import gql from 'graphql-tag';

export const LOGIN_USER_MUTATION = gql`
    mutation LoginMutation($usernameOrEmail: String!, $password: String!) {
      createUserLogin(input: {
        emailOrUsername: $usernameOrEmail
        password: $password
      }) {
        userLogin {
          response
          name
        }
      }
    }
 `

export const ME_USER_QUERY_AFTER_LOGIN = gql`
   query MeUser{
     meUser {
       _id
       firstName
       lastName
     }
   }
 `
<template>
  <!-- Page Content -->
  <div class="hero-static d-flex align-items-center">
    <div class="w-100">
      <!-- Sign In Section -->
      <div class="bg-white">
        <div class="content content-full">
          <b-row class="justify-content-center">
            <b-col md="8" lg="6" xl="4" class="py-4">
              <!-- Header -->
              <div class="text-center">
                <p class="mb-2">
                  <img src="@/assets/lazar-logo.png" alt="B2B platform" />
                </p>
                <h1 class="h4 mb-1">
                  Autentificare
                </h1>
                <h2 class="h6 font-w400 text-muted mb-3">
                  Importator si distribuitor componente notebook si accesorii PC
                </h2>
                <span v-if="formErrors != null" class="text-danger" v-text="formErrors"></span>
              </div>
              <!-- END Header -->

              <!-- Sign In Form -->
              <div v-if="dataLoad" class="text-center">
                <b-spinner variant="primary" label="Loading..."></b-spinner>
              </div>
              <b-form v-if="!dataLoad" @submit.stop.prevent="onSubmit">
                <div class="py-3">
                  <div class="form-group">
                    <b-form-input size="lg" class="form-control-alt" id="username" name="username" placeholder="Utilizator sau adresa de email" v-model="$v.form.username.$model" :state="!$v.form.username.$error && null" aria-describedby="username-feedback"></b-form-input>
                  </div>
                  <div class="form-group">
                    <b-form-input type="password" size="lg" class="form-control-alt" id="password" name="password" placeholder="Parola" v-model="$v.form.password.$model" :state="!$v.form.password.$error && null" aria-describedby="password-feedback"></b-form-input>
                  </div>
                  <div class="form-group">
                    <div class="d-md-flex align-items-md-center justify-content-md-between">
                      <div></div>
                      <div class="py-2">
                        <router-link to="/auth/reminder2" class="font-size-sm">Ai uitat parola?</router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <b-row class="form-group row justify-content-center mb-0">
                  <b-col md="6" xl="5">
                    <b-button type="submit" variant="primary" block>
                      <i class="fa fa-fw fa-sign-in-alt mr-1"></i> Authentificare
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <!-- END Sign In Form -->
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- END Sign In Section -->

      <!-- Footer -->
      <div class="font-size-sm text-center text-muted py-3">
        <strong>{{ $store.getters.appName + ' - ' + $store.getters.appVersion }}</strong> &copy; {{ $store.getters.appCopyright }}
      </div>
      <!-- END Footer -->
    </div>
  </div>
  <!-- END Page Content -->
</template>

<script>
// Vuelidate, for more info and examples you can check out https://github.com/vuelidate/vuelidate
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import {LOGIN_USER_MUTATION} from "@/constants/auth-graphql";
import {saveToken, saveUsername} from "@/data/logged-in-user";
import {getErrorMessage} from "../../../error/server-error";

export default {
  mixins: [validationMixin],
  data () {
    return {
      form: {
        username: null,
        password: null
      },
      formErrors: null,
      dataLoad: false,
    }
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3)
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    onSubmit () {
      this.formErrors = null;
      this.dataLoad = true;
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.$apollo.mutate({
        mutation: LOGIN_USER_MUTATION,
        variables: {
          'usernameOrEmail': this.$v.form.username.$model,
          'password': this.$v.form.password.$model
        }
      }).then((result) => {
        this.saveUserData(result.data.createUserLogin.userLogin.name, result.data.createUserLogin.userLogin.response);
        this.$router.push('/')
      }).catch((error) => {
        this.formErrors = getErrorMessage(error);
        this.dataLoad = false;
      })
    },
    saveUserData (name, token) {
      saveToken(token);
      saveUsername(name);
      this.$store.commit('setUsername', name);
    }
  }
}
</script>
